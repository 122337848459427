import React, { useState, useEffect } from "react";
import teamsData from './teams.json';
import './JudgesEvaluationForm.css';

function JudgesEvaluationForm({ userName }) {
    const [teams, setTeams] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [formData, setFormData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const containerUrl = 'https://drive.nyx.baby/nyxnocode';
    const sasToken = process.env.REACT_APP_SAS_TOKEN;
// Calculate total score dynamically
    const totalScore = Object.keys(formData)
        .filter((key) => !isNaN(parseInt(formData[key], 10)))
        .reduce((total, key) => total + parseInt(formData[key], 10), 0);

    useEffect(() => {
        // Load teams data
        try {
            setTeams(teamsData.teams || []);
            setIsLoading(false);
        } catch (error) {
            console.error("Error loading teams:", error);
            setError("Error loading teams data");
            setIsLoading(false);
        }
    }, []);
    useEffect(() => {
        try {
            setTeams(teamsData.teams);
            setIsLoading(false);
        } catch (error) {
            console.error("Error loading teams:", error);
            setError("Error loading teams data");
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (teams.length > 0) {
            const savedData = JSON.parse(localStorage.getItem(teams[currentIndex].id) || "{}");

            // Initialize fields with default values if not already set
            setFormData({
                creativity: savedData.creativity || "5",
                practicality: savedData.practicality || "5",
                accuracy: savedData.accuracy || "5",
                design: savedData.design || "5",
                technical: savedData.technical || "5",
                communityEngagement: savedData.communityEngagement || "5",
                nyxUniqueness: savedData.nyxUniqueness || "5",
                nyxCreativity: savedData.nyxCreativity || "5",
                nyxOriginality: savedData.nyxOriginality || "5",
                specialRecognition: savedData.specialRecognition || "",
                comments: savedData.comments || "",
            });
        }
    }, [currentIndex, teams]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Log changes to debug
        console.log(`Field updated: ${name}, Value: ${value}`);

        const updatedData = { ...formData, [name]: value };
        setFormData(updatedData);

        if (teams[currentIndex]) {
            localStorage.setItem(teams[currentIndex].id, JSON.stringify(updatedData));
        }
    };


    const handleSubmit = async () => {
        // Ensure all required fields have default values
        const requiredFields = [
            "creativity", "practicality", "accuracy", "design", "technical",
            "communityEngagement", "nyxUniqueness", "nyxCreativity",
            "nyxOriginality", "specialRecognition", "comments"
        ];

        // Fill missing fields with defaults
        const completedData = requiredFields.reduce((acc, field) => {
            acc[field] = formData[field] ?? (field === "specialRecognition" || field === "comments" ? "" : "5");
            return acc;
        }, {});

        // Update formData state with completed data
        setFormData(completedData);

        try {
            const now = new Date();
            const timestamp = `${now.getMonth() + 1}${now.getDate()}${now.getFullYear()}${now.getHours()}${now.getMinutes()}${now.getSeconds()}`;
            const filename = `${teams[currentIndex].id}@Mena${timestamp}.json`;
            const filePath = `${containerUrl}/${userName}/JUDGES ONLY/evaluations/${filename}?${sasToken}`;

            const evaluationData = {
                ...completedData,
                teamId: teams[currentIndex].id,
                teamName: teams[currentIndex].name,
                evaluatedBy: userName,
                evaluatedAt: now.toISOString(),
                projectUrl: teams[currentIndex].projectUrl,
            };

            const response = await fetch(filePath, {
                method: 'PUT',
                headers: {
                    'x-ms-blob-type': 'BlockBlob',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(evaluationData, null, 2),
            });

            if (!response.ok) {
                throw new Error(`Failed to save evaluation with status: ${response.status}`);
            }

            console.log("Submitting form data:", completedData);

            alert(`Evaluation for ${teams[currentIndex].name} submitted successfully!`);
            localStorage.removeItem(teams[currentIndex].id);
            setFormData({ ...completedData, submitted: true });
        } catch (error) {
            console.error("Error submitting evaluation:", error);
            alert(`Error submitting evaluation: ${error.message}`);
        }
    };



    const handleNavigation = (direction) => {


        if (direction === "next" && currentIndex < teams.length - 1) {
            setCurrentIndex(currentIndex + 1);
        } else if (direction === "prev" && currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    if (isLoading) return <div className="loading-message">Loading teams...</div>;
    if (error) return <div className="error-message">Error: {error}</div>;
    if (!teams.length) return <div className="error-message">No teams available for evaluation.</div>;

    const currentTeam = teams[currentIndex];

    return (
        <div className="evaluation-container">
            <h1 className="evaluation-title">Evaluation for {currentTeam.name}</h1>

            <a
                href={currentTeam.projectUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="project-link"
            >
                View Team Project Website
            </a>

            <form className="evaluation-form" onSubmit={(e) => e.preventDefault()}>
                <div className="form-group">
                    <label className="form-label">
                        Creativity and Innovation (1-10):
                        <div className="range-container">
                            <input
                                type="range"
                                name="creativity"
                                min="1"
                                max="10"
                                value={formData.creativity || "5"}
                                onChange={handleInputChange}
                                className="range-input"
                            />
                            <span className="range-value">{formData.creativity || "5"}</span>
                        </div>
                    </label>
                </div>

                <div className="form-group">
                    <label className="form-label">
                        Practicality and Impact (1-10):
                        <div className="range-container">
                            <input
                                type="range"
                                name="practicality"
                                min="1"
                                max="10"
                                value={formData.practicality || "5"}
                                onChange={handleInputChange}
                                className="range-input"
                            />
                            <span className="range-value">{formData.practicality || "5"}</span>
                        </div>
                    </label>
                </div>
                <div className="form-group">
                    <label className="form-label">
                        Accuracy and Completeness (1-10):
                        <div className="range-container">
                            <input
                                type="range"
                                name="accuracy"
                                min="1"
                                max="10"
                                value={formData.accuracy || "5"}
                                onChange={handleInputChange}
                                className="range-input"
                            />
                            <span className="range-value">{formData.accuracy || "5"}</span>
                        </div>
                    </label>
                </div>

                {/* New criteria */}
                <div className="form-group">
                    <label className="form-label">
                        Design and User Experience (1-10):
                        <div className="range-container">
                            <input
                                type="range"
                                name="design"
                                min="1"
                                max="10"
                                value={formData.design || "5"}
                                onChange={handleInputChange}
                                className="range-input"
                            />
                            <span className="range-value">{formData.design || "5"}</span>
                        </div>
                    </label>
                </div>

                <div className="form-group">
                    <label className="form-label">
                        Technical Implementation (1-10):
                        <div className="range-container">
                            <input
                                type="range"
                                name="technical"
                                min="1"
                                max="10"
                                value={formData.technical || "5"}
                                onChange={handleInputChange}
                                className="range-input"
                            />
                            <span className="range-value">{formData.technical || "5"}</span>
                        </div>
                    </label>
                </div>

                <div className="form-group">
                    <label className="form-label">
                        Community Engagement (1-10):
                        <div className="range-container">
                            <input
                                type="range"
                                name="communityEngagement"
                                min="1"
                                max="10"
                                value={formData.communityEngagement || "5"}
                                onChange={handleInputChange}
                                className="range-input"
                            />
                            <span className="range-value">{formData.communityEngagement || "5"}</span>
                        </div>
                    </label>
                </div>

                <div className="form-group">
                    <label className="form-label">
                        Uniqueness of Nyx NoCode Usage (1-10):
                        <div className="range-container">
                            <input
                                type="range"
                                name="nyxUniqueness"
                                min="1"
                                max="10"
                                value={formData.nyxUniqueness || "5"}
                                onChange={handleInputChange}
                                className="range-input"
                            />
                            <span className="range-value">{formData.nyxUniqueness || "5"}</span>
                        </div>
                    </label>
                </div>

                <div className="form-group">
                    <label className="form-label">
                        Creative Problem-Solving Approach (1-10):
                        <div className="range-container">
                            <input
                                type="range"
                                name="nyxCreativity"
                                min="1"
                                max="10"
                                value={formData.nyxCreativity || "5"}
                                onChange={handleInputChange}
                                className="range-input"
                            />
                            <span className="range-value">{formData.nyxCreativity || "5"}</span>
                        </div>
                    </label>
                </div>

                <div className="form-group">
                    <label className="form-label">
                        Originality in Concept (1-10):
                        <div className="range-container">
                            <input
                                type="range"
                                name="nyxOriginality"
                                min="1"
                                max="10"
                                value={formData.nyxOriginality || "5"}
                                onChange={handleInputChange}
                                className="range-input"
                            />
                            <span className="range-value">{formData.nyxOriginality || "5"}</span>
                        </div>
                    </label>
                </div>


                <div className="form-group">
                    <label className="form-label">
                        Special Recognition / Judges' Choice:
                        <textarea
                            name="specialRecognition"
                            value={formData.specialRecognition || ""}
                            onChange={handleInputChange}
                            className="comments-input"
                            placeholder="Enter any additional comments or standout features..."
                        />
                    </label>
                </div>
                <div>Click Save Evaluation to save current team's evaluation.</div>
                <div>Click Previous or Next to navigate among teams.</div>
                <div>You can review your saved Evaluations in the Gallery.</div>


                <div className="button-container">
                    <div className="total-score-display">
                        <h3>Total Points: {totalScore}</h3>
                    </div>
                    <button
                        onClick={() => handleNavigation("prev")}
                        disabled={currentIndex === 0}
                        className="nav-button"
                    >
                        Previous
                    </button>

                    <button
                        onClick={handleSubmit}
                        className="submit-button"
                    >
                        Save Evaluation
                    </button>

                    <button
                        onClick={() => handleNavigation("next")}
                        disabled={currentIndex === teams.length - 1}
                        className="nav-button"
                    >
                        Next
                    </button>
                </div>

                <div className="progress-container">
                    <div className="progress-text">
                        Entry {currentIndex + 1} of {teams.length}
                    </div>
                    <div className="progress-bar">
                        <div
                            className="progress-fill"
                            style={{
                                width: `${((currentIndex + 1) / teams.length) * 100}%`
                            }}
                        />
                    </div>
                    <h1 className="evaluation-title">Evaluation for {currentTeam.name}</h1>

                    <a
                        href={currentTeam.projectUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="project-link"
                    >
                        View Team Project Website
                    </a>
                </div>
            </form>
        </div>
    );
}

export default JudgesEvaluationForm;
